import React from 'react'
import type { GetStaticProps, NextPage } from 'next'

// Components
import Head from 'next/head'
import Grid, { Col } from '@/components/meta/Grid'
import ButtonLink from '@/components/atoms/ButtonLink'

// Api
import { getErrorPageByCode } from '@/lib/api'

// Styles
import styles from '@/styles/pages/404.module.scss'
import { renderMetaTags } from 'react-datocms'

export interface IErrorComponent {
  data: {
    error: {
      title: string
      heading: string
      text: string
      errorCode: string
    }
  }
}

export const Error = ({
  data
}: IError) => {
  const metaTags = [...data.error?.seo ?? [], ...data.site.favicon]

  return (
    <>
      <Head>{renderMetaTags(metaTags)}</Head>
      <Grid as="main" className={styles.custom404} size="l">
        <Col span={12}>
          <p className="heading-1-xl">{data.error?.title}</p>
          <p className="body-l">{data.error?.description}</p>
          <h1 className={styles['error-title']}>{data.error?.code}</h1>
          <ButtonLink href="/" variation="secondary">Return to home</ButtonLink>
        </Col>
      </Grid>
    </>
  )
}

const Custom404: NextPage<IError> = ({ data }) => {
  return (
    <Error data={data} />
  )
}

export const getStaticProps: GetStaticProps = async({ preview }) => {
  const error = await getErrorPageByCode('404', preview)

  const data: IError = {
    ...error
  }

  return {
    props: { data }
  }
}

export default Custom404
